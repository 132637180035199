<template>
  <!-- 黑名单列表 -->
  <div class="blackList">
    <div class="search">
      <el-input placeholder="搜索玩家昵称/手机号码" style="width: 250px; margin-right: 10px" prefix-icon="el-icon-search" v-model="queryParam"></el-input>
      <el-button type="primary" @click="exportDataList">导出名单</el-button>
      <el-button type="primary" @click="getDataList(queryParam)">查询</el-button>
    </div>
    <el-table
      :data="dataList"
      style="width: 100%;">
      <el-table-column
        header-align="center"
        align="center"
        label="玩家头像">
        <template slot-scope="scope">
          <img :src="scope.row.headImgUrl" alt="" v-if="scope.row.headImgUrl" width="50px" height="50px" style="border-radius: 50%">
        </template>
      </el-table-column>
      <el-table-column
        prop="nickname"
        header-align="center"
        align="center"
        label="玩家昵称">
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="phone"
        header-align="center"
        align="center"
        label="联系电话">
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="首次参加时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="200"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="setBlackList(scope.row.id)">移出黑名单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
import { getBlacklist, removePlayerBlacklist } from '@/api/market'
import { getLocal } from '@/lib/local'

export default {
  data () {
    return {
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      queryParam:'',
      activityId: this.$route.query.activityId || ''
    }
  },
  // created () {
  //   this.getDataList()
  // },
   methods: {
    //  查询数据
    async getDataList (queryParam) {
      const res = await getBlacklist({pageNO: this.pageIndex, pageSize: this.pageSize, activityId: this.activityId, queryParam})
      if(res.code == 200) {
        this.dataList = res.data.list
        this.totalPage = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList(this.queryParam)
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList(this.queryParam)
    },
    // 移入黑名单
    async setBlackList (playerId) {
      const res = await removePlayerBlacklist({playerId})
      if(res.code == 200) {
        this.$message.success('移出黑名单成功')
        this.pageIndex = 1
        this.getDataList()
      }
    },
    // 导出
    async exportDataList () {
      window.location.href = `${this.$url}/cube-client/activity/player/export/order/black?activityId=${this.activityId}&queryParam=${this.queryParam}&token=${getLocal()}`
      // window.location.href = `http://192.168.9.149:9003/cube-client/activity/player/export/order/black?activityId=${this.activityId}&queryParam=${this.queryParam}&token=${getLocal()}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .blackList {
    .el-pagination {
      margin-top: 20px;
      text-align: right;
    }
    .search {
      margin: 20px 0;
    }
  }
</style>