var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dataShow" }, [
    _c("div", { staticClass: "detail" }, [
      _vm._m(0),
      _c("div", { staticClass: "detail-content" }, [
        _vm.detailData.templePage
          ? _c("div", { staticClass: "left" }, [
              _c("p", [_vm._v("活动名称：" + _vm._s(_vm.detailData.name))]),
              _c("p", [
                _vm._v(
                  "活动类型：" +
                    _vm._s(_vm.activeType[_vm.detailData.templeType])
                )
              ]),
              _c("p", [
                _vm._v(
                  "活动模板：" +
                    _vm._s(
                      JSON.parse(_vm.detailData.templePage).activityBase.name
                    )
                )
              ]),
              _c("p", [
                _vm._v(
                  "活动时间：" +
                    _vm._s(
                      _vm.detailData.beginTime + "~" + _vm.detailData.endTime
                    )
                )
              ]),
              _vm.status == 2 && !_vm.detailData.status
                ? _c("p", [
                    _vm._v("活动状态："),
                    _c("span", { style: { color: "#ff9900" } }, [
                      _vm._v(_vm._s("已暂停"))
                    ])
                  ])
                : _c("p", [
                    _vm._v("活动状态："),
                    _c(
                      "span",
                      { style: { color: _vm.statusList[_vm.status].color } },
                      [_vm._v(_vm._s(_vm.statusList[_vm.status].text))]
                    )
                  ])
            ])
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "overall-trend" }, [
      _c("h4", { staticClass: "detail-title" }, [
        _c("i"),
        _vm._v(" 整体趋势 "),
        _c(
          "ul",
          { staticClass: "show-time" },
          _vm._l(_vm.timeList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                class: { active: index == _vm.current },
                on: {
                  click: function($event) {
                    return _vm.getTimeData(index)
                  }
                }
              },
              [_vm._v(_vm._s(item))]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "set-time" },
          [
            _c("span", { staticStyle: { fontSize: "12px" } }, [
              _vm._v("活动期间")
            ]),
            _c("el-date-picker", {
              attrs: {
                type: "date",
                placeholder: "选择开始时间",
                size: "small",
                "picker-options": _vm.startDatePicker,
                "value-format": "yyyy-MM-dd"
              },
              model: {
                value: _vm.form.start,
                callback: function($$v) {
                  _vm.$set(_vm.form, "start", $$v)
                },
                expression: "form.start"
              }
            }),
            _c("el-date-picker", {
              attrs: {
                type: "date",
                placeholder: "选择结束时间",
                size: "small",
                "picker-options": _vm.endDatePicker,
                "value-format": "yyyy-MM-dd"
              },
              model: {
                value: _vm.form.end,
                callback: function($$v) {
                  _vm.$set(_vm.form, "end", $$v)
                },
                expression: "form.end"
              }
            }),
            _c(
              "el-button",
              {
                attrs: { type: "success", size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("查询")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-title" }, [
        _vm.echartsData
          ? _c("ul", { staticClass: "numberShow" }, [
              _c("li", [
                _vm._v("浏览人数："),
                _c("span", { staticStyle: { color: "#019fe8" } }, [
                  _vm._v(_vm._s(_vm.echartsData.totalVisitUser))
                ])
              ]),
              _c("li", [
                _vm._v("参与人数："),
                _c("span", { staticStyle: { color: "#019fe8" } }, [
                  _vm._v(_vm._s(_vm.echartsData.totalJoinUser))
                ])
              ]),
              _c("li", [
                _vm._v("获奖人数："),
                _c("span", { staticStyle: { color: "#019fe8" } }, [
                  _vm._v(_vm._s(_vm.echartsData.totalWinUser))
                ])
              ]),
              _c("li", [
                _vm._v("分享人数："),
                _c("span", { staticStyle: { color: "#019fe8" } }, [
                  _vm._v(_vm._s(_vm.echartsData.totalShareUser))
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "trend-content" }, [
        _c("div", { ref: "chart", staticClass: "chart" })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "detail-title" }, [
      _c("i"),
      _vm._v(" 活动详细")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }