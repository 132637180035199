<template>
  <!-- 传播数据 -->
  <div class="dataShow">
    <div class="detail">
      <h4 class="detail-title"><i></i> 活动详细</h4>
      <div class="detail-content">
        <div class="left" v-if="detailData.templePage">
          <p>活动名称：{{ detailData.name }}</p>
          <p>活动类型：{{ activeType[detailData.templeType] }}</p>
          <p>活动模板：{{ JSON.parse(detailData.templePage).activityBase.name }}</p>
          <p>活动时间：{{detailData.beginTime + '~' + detailData.endTime}}</p>
          <p v-if="status == 2 && !detailData.status">活动状态：<span :style="{color: '#ff9900'}">{{ '已暂停' }}</span></p>
          <p v-else>活动状态：<span :style="{color: statusList[status].color}">{{ statusList[status].text }}</span></p>
        </div>
      </div>
    </div>
    <div class="overall-trend">
      <h4 class="detail-title"><i></i> 
        整体趋势
        <ul class="show-time">
          <li v-for="(item, index) in timeList" :key="index" :class="{active: index == current}" @click="getTimeData(index)">{{ item }}</li>
        </ul>
        <div class="set-time">
          <span style="fontSize: 12px">活动期间</span>
          <el-date-picker v-model="form.start" type="date" placeholder="选择开始时间" size="small" :picker-options="startDatePicker" value-format="yyyy-MM-dd"></el-date-picker>
          <el-date-picker v-model="form.end" type="date" placeholder="选择结束时间" size="small" :picker-options="endDatePicker" value-format="yyyy-MM-dd"></el-date-picker>
          <el-button  type="success" size="small" @click="search">查询</el-button>
        </div>
      </h4>
      <div class="detail-title">
        <ul class="numberShow" v-if="echartsData">
          <li>浏览人数：<span style="color: #019fe8">{{ echartsData.totalVisitUser }}</span></li>
          <li>参与人数：<span style="color: #019fe8">{{ echartsData.totalJoinUser }}</span></li>
          <li>获奖人数：<span style="color: #019fe8">{{ echartsData.totalWinUser }}</span></li>
          <li>分享人数：<span style="color: #019fe8">{{ echartsData.totalShareUser }}</span></li>
        </ul>
      </div>
      <div class="trend-content">
        <div class="chart" ref="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getChartData, getDetail } from '@/api/market'

export default {
  data () {
    return {
      current: 2,
      form: {
        start: '',
        end: ''
      },
      timeList: ['今日', '昨日', '最近七日', '最近30日', '本月'],
      activityId: this.$route.query.activityId || '',
      status: this.$route.params.status || '',
      detailData: {},
      activeType: ['开宝箱','九宫格','砸金蛋','大转盘','小兔兔奔月记','红旗护卫','画金蛋','幸运大翻转','欢乐抽奖机','阅读有礼'],
      statusList: [
        {
          color: '#ff9900',
          text: '未发布'
        },
        {
          color: '#ff9900',
          text: '未开始'
        },
        {
          color: '#009900',
          text: '进行中'
        },
        {
          color: '#aeaeae',
          text: '已结束'
        },
      ],
      echartsData: '',
      timeData: [],
      visitUserData: [],
      joinUserData: [],
      winUserData: [],
      shareUserData: [],
      option: {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['浏览人数', '参与人数', '获奖人数', '分享人数'],
            left: '10%'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {
                  type: 'png',
                  title: ''
                }
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
        },
        yAxis: {
            type: 'value',
            minInterval: 1
        },
        series: [
            {
                name: '浏览人数',
                type: 'line',
                // stack: '总量',
                symbol: 'circle',
                // data: [120, 132, 101, 134, 90, 230, 210]
                data: []
            },
            {
                name: '参与人数',
                type: 'line',
                // stack: '总量',
                symbol: 'circle',
                // data: [220, 182, 191, 234, 290, 330, 310]
                data: []
            },
            {
                name: '获奖人数',
                type: 'line',
                // stack: '总量',
                symbol: 'circle',
                // data: [150, 232, 201, 154, 190, 330, 410]
                data: []
            },
            {
                name: '分享人数',
                type: 'line',
                // stack: '总量',
                symbol: 'circle',
                // data: [320, 332, 301, 334, 390, 330, 320]
                data: []
            }
        ]
      },
      activityEndTime: '',
      myChart: '',
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate(),
    }
  },
  created () {
    this.getActiveDetail().then(() => {
      this.getChart()
    })
  },
  mounted () {
    this.myChart = echarts.init(this.$refs.chart)
    this.myChart.setOption(this.option)
  },
  methods: {
    // 获取图表数据
    async getChart (timeAnchor='w', beginTime='',endTime='') {
      const res = await getChartData({activityId: this.activityId, timeAnchor, beginTime, endTime, activityEndTime: this.activityEndTime})
      if(res.code == 200) {
        this.echartsData = res.data
        this.option.xAxis.data = []
        this.option.series[0].data = []
        this.option.series[1].data = []
        this.option.series[2].data = []
        this.option.series[3].data = []
        if(this.echartsData.list.length) {
          this.echartsData.list.forEach((item) => {
            if(timeAnchor == 't' || timeAnchor == 'y') {
              this.option.xAxis.data.push(item.hour)
            }else {
              this.option.xAxis.data.push(item.time.split('-')[1] + '-' + item.time.split('-')[2])
            }
            this.option.series[0].data.push(item.visitUser.toString())
            this.option.series[1].data.push(item.joinUser.toString())
            this.option.series[2].data.push(item.winUser.toString())
            this.option.series[3].data.push(item.shareUser.toString())
            this.myChart.setOption(this.option)
          })
        }
      }
    },
    // 获取活动详细数据
    async getActiveDetail () {
      const res = await getDetail({id: this.activityId})
      if(res.code == 200) {
        this.detailData = res.data
        this.activityEndTime = res.data.endTime
        this.$emit('getActiveTitle', this.detailData)
      }
    },
    // 根据时间获取数据
    getTimeData (index) {
      this.current = index
      switch(index) {
        case 0:
          this.getChart('t')
          break;
        case 1: 
          this.getChart('y')
          break;
        case 2:
          this.getChart('w')
          break;
        case 3:
          this.getChart('m')
          break;
        case 4:
          this.getChart('x')
      }
    },
    search() {
      if(new Date(this.form.end).getTime() - new Date(this.form.start).getTime() > 30 * 24 * 3600 * 1000) {
        this.$message.error('时间间隔不能超过31天')
      }else {
        this.getChart('d', this.form.start, this.form.end)
      }
    },
    beginDate(){
      const self = this
      return {
        disabledDate(time){
          if (self.form.end) {  //如果结束时间不为空，则小于结束时间
            return new Date(self.form.end).getTime()< time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    },
    processDate() {
      const self = this
      return {
        disabledDate(time) {
          if (self.form.start) {  //如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.form.start).getTime() > time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .dataShow {
    .overall-trend,
    .detail {
      border: 5px solid #f8f9fa;
      width: 100%;
      height: 280px;
      .detail-title {
        display: flex;
        align-items: center;
        height: 55px;
        padding: 0 10px;
        font-weight: 400;
        border-bottom: 1px solid #eee;
        .show-time {
          display: flex;
          width: 30%;
          margin-left: 13%;
          justify-content: space-around;
          font-size: 12px;
          li {
            cursor: pointer;
          }
        }
        .active {
          color: #019fe8;
        }
        .set-time {
          width: 50%;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        i {
          display: inline-block;
          width: 5px;
          height: 20px;
          margin-right: 10px;
          background-color: #329fea;
        }
      }
      .detail-content {
        padding: 20px;
        p {
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          color: #666;
        }
      }
    }
    .overall-trend {
      margin-top: 10px;
      height: 500px;
    }
    .chart {
      width: 100%;
      height: 350px;
      margin-top: 20px;
    }
    .numberShow {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      li {
        font-size: 12px;
        margin-right: 20px;
        &:nth-child(1) {
          margin-left: 70%;
        }
      }
    }
  }
</style>