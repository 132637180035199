var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prizeData" },
    [
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.dataList } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "prizeName",
              "header-align": "center",
              align: "center",
              width: "150",
              label: "奖品名称"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "prizeRank",
              "header-align": "center",
              align: "center",
              label: "奖品等级"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              "header-align": "center",
              align: "center",
              label: "奖品类型"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "leftNum",
              "header-align": "center",
              align: "center",
              width: "120",
              label: "奖池剩余数量"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "winNum",
              "header-align": "center",
              align: "center",
              label: "已中奖"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "receivedNum",
              "header-align": "center",
              align: "center",
              label: "已领取"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "verificationNum",
              "header-align": "center",
              align: "center",
              label: "已核销"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "notReceivedNum",
              "header-align": "center",
              align: "center",
              label: "未领取"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "expiredNum",
              "header-align": "center",
              align: "center",
              label: "已过期"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invalidNum",
              "header-align": "center",
              align: "center",
              label: "已失效"
            }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              "header-align": "center",
              align: "center",
              width: "200",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.editActive(scope.row.prizeRank)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _vm.status == 3 && scope.row.notReceivedNum
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.returnResource(scope.row.prizeId)
                              }
                            }
                          },
                          [_vm._v("退回资产")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small", disabled: "" }
                          },
                          [_vm._v("退回资产")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }