/**
 * @ Author: diogoxiang@163.com
 * @ Create Time: 2022-05-26 09:36:59
 * @ Modified by: diogoxiang@163.com
 * @ Modified time: 2022-05-26 10:14:02
 * @ Description: 通用类型的 方法或是参数  通用查询时间类参数
 */

var myMixin = {
  data () {
    return {
      form: {
        startTime: '',
        endTime: ''
      },
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate()
    }
  },
  methods: {
    /**
     * 通用类型导出 导出的url
     */
    exportDataList (exportURL, queryParams) {
      window.location.href = this.$url + exportURL + '?' + queryParams
    },
    beginDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.form.endTime) { // 如果结束时间不为空，则小于结束时间
            return new Date(self.form.endTime).getTime() < time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    },
    processDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.form.startTime) { // 如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.form.startTime).getTime() > time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    }
  }
}

export default myMixin
