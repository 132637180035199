<template>
  <div class="approvalImage">
    <div>
      审核状态：
      <el-select v-model="state" placeholder="请选择" style="margin-right: 6px;" @change="init">
        <el-option label="全部" value=""></el-option>
        <el-option label="待审核" value="0"></el-option>
        <el-option label="审核通过" value="1"></el-option>
        <el-option label="驳回" value="2"></el-option>
      </el-select>
    </div>
    <el-table
      :data="dataList"
      style="width: 100%;">
      <el-table-column
        prop="userName"
        align="center"
        label="昵称">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="图片">
        <template slot-scope="scope">
          <el-image fit="contain" style="width: 50px;height: auto" :src="scope.row.pictureUrl" :preview-src-list="[scope.row.pictureUrl]" alt="" v-if="scope.row.pictureUrl"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态">
        <template slot-scope="scope">
          <template v-if="scope.row.state == 0">
            <el-tag type="warning">未审核</el-tag>
          </template>
          <template v-if="scope.row.state == 1">
            <el-tag type="success">通过</el-tag>
          </template>
          <template v-if="scope.row.state == 2">
            <el-tag type="danger">驳回</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <template v-if="scope.row.state == 0">
            <el-button type="success"  size="small" @click="approval(scope.row)">通过</el-button>
            <el-button type="danger" size="small" @click="refuse(scope.row)">驳回</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="text-align: right;margin-top: 20px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
import { picturePage, auditPicture } from '@/api/market'
export default {
  name: 'approvalImage',
  data () {
    return {
      state: '',
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0
    }
  },
  methods: {
    init () {
      this.dataList = []
      this.pageIndex = 1
      this.queryList()
    },
    queryList () {
      picturePage({
        pageNO: this.pageIndex,
        pageSize: this.pageSize,
        activityId: this.$route.query.activityId,
        state: this.state
      }).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.list
          this.totalPage = res.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.queryList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.queryList()
    },
    // 通过
    approval (row) {
      this.$confirm('审核通过后不可撤销，不可撤销!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        auditPicture({
          pid: row.id,
          activityId: row.activityId,
          state: 1,
          remark: ''
        }).then(res => {
          this.queryList()
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '已通过!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 驳回
    refuse (row) {
      this.$prompt('请输入不通过理由', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: function (value) {
          if (value.toString().length > 25) {
            return '长度限制25个字符'
          }
          // 禁止输入特殊字符
          const disabled = ['?', '#', '=', '&']
          for (const item of disabled) {
            if (value.indexOf(item) >= 0) {
              return '禁止输入' + disabled.join(',') + '等特殊字符'
            }
          }
          return true
        }
        // inputErrorMessage:
      }).then(({ value }) => {
        // console.log('remark:', value)
        auditPicture({
          pid: row.id,
          activityId: row.activityId,
          state: 2,
          remark: value
        }).then(res => {
          this.queryList()
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '已驳回!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
