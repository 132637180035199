var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "getPrize" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            staticStyle: { width: "250px", "margin-right": "10px" },
            attrs: {
              placeholder: "搜索玩家昵称/手机号码",
              "prefix-icon": "el-icon-search"
            },
            model: {
              value: _vm.parameter,
              callback: function($$v) {
                _vm.parameter = $$v
              },
              expression: "parameter"
            }
          }),
          _vm._v(" 物资类型： "),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "6px" },
              attrs: { placeholder: "请选择" },
              on: { change: _vm.searchInfo },
              model: {
                value: _vm.productType,
                callback: function($$v) {
                  _vm.productType = $$v
                },
                expression: "productType"
              }
            },
            _vm._l(_vm.typeList, function(type, index) {
              return _c("el-option", {
                key: type.value,
                attrs: { label: type.label, value: type.value }
              })
            }),
            1
          ),
          _vm._v(" 订单状态： "),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "3px" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.orderStatus,
                callback: function($$v) {
                  _vm.orderStatus = $$v
                },
                expression: "orderStatus"
              }
            },
            _vm._l(_vm.statusList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.name, value: item.value }
              })
            }),
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("el-date-picker", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  type: "datetime",
                  placeholder: "选择开始时间",
                  "picker-options": _vm.startDatePicker,
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.form.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "startTime", $$v)
                  },
                  expression: "form.startTime"
                }
              }),
              _c("el-date-picker", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  type: "datetime",
                  placeholder: "选择结束时间",
                  "picker-options": _vm.endDatePicker,
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.form.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "endTime", $$v)
                  },
                  expression: "form.endTime"
                }
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchInfo } },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.exportDataList }
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.showFileList } },
                [_vm._v("导出列表")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.dataList } },
        [
          _c("el-table-column", {
            attrs: {
              prop: "nickname",
              "header-align": "center",
              align: "center",
              label: "中奖玩家"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              "header-align": "center",
              align: "center",
              label: "姓名"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phone",
              "header-align": "center",
              align: "center",
              label: "手机号码"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "prizeRank",
              "header-align": "center",
              align: "center",
              label: "奖项等级"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "prizeName",
              "header-align": "center",
              align: "center",
              label: "奖品"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              "header-align": "center",
              align: "center",
              width: "180",
              label: "中奖时间"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderStatus",
              "header-align": "center",
              align: "center",
              label: "奖品兑换状态"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ext",
              "header-align": "center",
              align: "center",
              width: "180",
              label: "收货信息"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticStyle: { "text-align": "left" } }, [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.ext
                                ? "联系人：" +
                                    JSON.parse(scope.row.ext).contactsPerson
                                : ""
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.ext
                                ? "手机号：" +
                                    JSON.parse(scope.row.ext).contactsPhone
                                : ""
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.ext
                                ? "地址：" +
                                    JSON.parse(scope.row.ext).allAddress
                                : ""
                            ) +
                            " "
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ext",
              "header-align": "center",
              align: "center",
              width: "180",
              label: "下单失败原因"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.ext
                            ? JSON.parse(scope.row.ext).errCodeDes
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ext",
              "header-align": "center",
              align: "center",
              width: "180",
              label: "下单订单号"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.ext
                            ? JSON.parse(scope.row.ext).suppliesOrderNo
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "200",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.orderStatus != "已核销" &&
                    scope.row.productType == "myself"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: scope.row.orderStatus != "未领取"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setVerificationStatus(scope.row)
                              }
                            }
                          },
                          [_vm._v("核销")]
                        )
                      : scope.row.orderStatus == "已核销"
                      ? _c(
                          "el-button",
                          { attrs: { type: "text", disabled: "" } },
                          [_vm._v("已核销")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pageIndex,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.pageSize,
          total: _vm.totalPage,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "size-change": _vm.sizeChangeHandle,
          "current-change": _vm.currentChangeHandle
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "核销奖品",
            visible: _vm.visible,
            width: "40%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("p", { staticClass: "tips" }, [_vm._v("请仔细核对奖品信息！")]),
          _vm.prizeData
            ? _c("ul", { staticClass: "prizeInfo" }, [
                _c("li", [
                  _vm._v("中奖玩家：" + _vm._s(_vm.prizeData.nickname))
                ]),
                _c("li", [_vm._v("手机号码：" + _vm._s(_vm.prizeData.phone))]),
                _c("li", [
                  _vm._v("奖项等级：" + _vm._s(_vm.prizeData.prizeRank))
                ]),
                _c("li", [_vm._v("奖品：" + _vm._s(_vm.prizeData.prizeName))]),
                _c("li", [_vm._v("中奖券码：" + _vm._s(_vm.prizeData.id))]),
                _c("li", [
                  _vm._v("中奖时间：" + _vm._s(_vm.prizeData.createTime))
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-top": "50px", "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定核销")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出文件列表",
            visible: _vm.showExportListDialog,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showExportListDialog = $event
            }
          }
        },
        _vm._l(_vm.exportFileList, function(file, index) {
          return _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "margin-bottom": "20px"
              }
            },
            [
              _c("div", [_vm._v(_vm._s(file.fileName))]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.down(file.fileName)
                    }
                  }
                },
                [_vm._v("下载")]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }