<template>
  <div class="openDraw">
    <div style="text-align: center;margin: 20px"></div>
    <el-form :model="formInline" :disabled="$route.query.activityStatus != 3 || !allowChange" label-width="400px" class="demo-form-inline">
      <el-form-item label="选择开奖冠军队伍：">
        <el-select v-model="formInline.team" placeholder="选择开奖队伍">
          <el-option v-for="(team,index) of teamList"
                     :label="team.teamName"
                     :value="team.id" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-tag style="margin-left: 20px" v-if="$route.query.activityStatus != 3" type="danger">活动结束后可选择</el-tag>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryTeam, queryWin, queryQuizResult } from '@/api/market'
export default {
  name: 'openDraw',
  data () {
    return {
      formInline: {
        team: ''
      },
      allowChange: true,
      teamList: []
    }
  },
  mounted () {
    queryTeam().then(res => {
      if (res.code === 200) {
        this.teamList = res.data
      } else {
        this.$message.error(res.msg)
      }
    })
    this.queryResult()
  },
  methods: {
    queryResult () {
      queryQuizResult({
        activityId: this.$route.query.activityId
      }).then(res => {
        if (res.code === 200 && res.data) {
          this.formInline.team = res.data.teamId
          this.allowChange = res.data.status
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onSubmit () {
      queryWin({
        activityId: this.$route.query.activityId,
        teamId: this.formInline.team
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功，半小时内可修改开奖结果。')
          this.queryResult()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
