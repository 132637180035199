<template>
  <div class="reissue">
    <div class="search">
      <el-input clearable placeholder="订单号" style="width: 250px; margin-right: 10px" prefix-icon="el-icon-search" v-model="orderId"></el-input>
      <el-input clearable placeholder="手机号码" maxlength="11" style="width: 250px; margin-right: 10px" prefix-icon="el-icon-search" v-model="phone"></el-input>
      <el-date-picker
        v-model="time"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" style="margin-left: 10px" @click="init">查询</el-button>
    </div>
    <el-table
      :data="dataList"
      style="width: 100%;">
      <el-table-column
        v-if="success"
        prop="updateTime"
        align="center"
        label="更新时间">
      </el-table-column>
      <el-table-column
        prop="createTime"
        align="center"
        :width="success? '' : 100"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="orderId"
        align="center"
        :width="success? '' : 100"
        label="订单号">
      </el-table-column>
      <el-table-column
        prop="phone"
        align="center"
        label="手机号">
      </el-table-column>
      <el-table-column
        v-if="!success"
        prop="errCode"
        align="center"
        label="异常code">
      </el-table-column>
      <el-table-column
        v-if="!success"
        prop="errCodeDes"
        align="center"
        label="异常信息描述">
      </el-table-column>
      <el-table-column
        prop="amount"
        align="center"
        width="100"
        label="金额(分)">
      </el-table-column>
      <el-table-column
        prop="mchId"
        align="center"
        label="商户号">
      </el-table-column>
      <el-table-column
        prop="mchAppid"
        align="center"
        label="appid">
      </el-table-column>
      <el-table-column
        v-if="!success"
        fixed="right"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <template v-if="scope.row.retry > 0 && scope.row.sendStatus == 0">
            <el-button type="text" size="small" @click="reissue(scope.row.id)">重试补发</el-button>
          </template>
          <template v-else>
            <template v-if="scope.row.sendStatus == 0">待处理</template>
            <template v-if="scope.row.sendStatus == 1">处理中</template>
            <template v-if="scope.row.sendStatus == 2">已处理</template>
          </template>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px;margin-bottom: 20px;float: right"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
import { failRecord, retrySendOut, queryManuallyRecord } from '@/api/market'
import { throttle } from '@/utils/index'
export default {
  name: 'prizeReissue',
  data () {
    return {
      orderId: '',
      phone: '',
      time: null,
      pageNo: 1,
      pageSize: 10,
      dataList: [],
      total: 0
    }
  },
  props: {
    success: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.init()
    console.log('success=', this.sucess)
  },
  methods: {
    init () {
      this.pageNo = 1
      this.query()
    },
    // 查询
    query () {
      (this.success ? queryManuallyRecord : failRecord)({
        endTime: this.time ? this.time[1] : '',
        orderId: this.orderId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        phone: this.phone,
        startTime: this.time ? this.time[0] : ''
      }).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 补发重试
    reissue: throttle(function (recordId) {
      retrySendOut({
        recordId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('补发重试中')
          this.query()
        } else {
          this.$message.error(res.msg)
        }
      })
    }, 1500),
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageNo = 1
      this.query()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageNo = val
      this.query()
    }
  }
}
</script>

<style scoped>

</style>
