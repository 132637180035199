<template>
  <!-- 获奖名单 -->
  <div class="getPrize">
    <div class="search">
      <el-input placeholder="搜索玩家昵称/手机号码" style="width: 250px; margin-right: 10px" prefix-icon="el-icon-search" v-model="parameter"></el-input>
	  物资类型：
	  <el-select v-model="productType" placeholder="请选择" style="margin-right: 6px;" @change="searchInfo">
			<el-option v-for="(type,index) of typeList"
			 :label="type.label" :value="type.value" :key="type.value" ></el-option>
	  </el-select>
	  订单状态：
      <el-select v-model="orderStatus" placeholder="请选择" style="margin-right: 3px;">
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="item.name"
          :value="item.value">
        </el-option>
      </el-select>
     <!-- <div class="search-time">

      </div> -->
	  <div style="margin-top: 10px;">
		  <el-date-picker style="margin-right: 10px;" v-model="form.startTime" type="datetime" placeholder="选择开始时间" :picker-options="startDatePicker" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
		  <el-date-picker style="margin-right: 10px;" v-model="form.endTime" type="datetime" placeholder="选择结束时间" :picker-options="endDatePicker" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
		  <el-button type="primary" @click="searchInfo">查询</el-button>
		  <el-button type="primary" @click="exportDataList">导出</el-button>
		  <el-button type="primary" @click="showFileList">导出列表</el-button>
	  </div>
    </div>
    <el-table
      :data="dataList"
      style="width: 100%;">
      <el-table-column
        prop="nickname"
        header-align="center"
        align="center"
        label="中奖玩家">
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="phone"
        header-align="center"
        align="center"
        label="手机号码">
      </el-table-column>
      <el-table-column
        prop="prizeRank"
        header-align="center"
        align="center"
        label="奖项等级">
        <!-- <template slot-scope="scope">
          <span>{{ prizeRankList[scope.row.orderPrizeItem[0].prizeRank - 1]  }}</span>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="prizeName"
        header-align="center"
        align="center"
        label="奖品">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.orderPrizeItem[0].prizeName }}</span>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="180"
        label="中奖时间">
      </el-table-column>
      <el-table-column
        prop="orderStatus"
        header-align="center"
        align="center"
        label="奖品兑换状态">
        <!-- <template slot-scope="scope"> -->
          <!-- <span v-if="scope.row.orderStatus == 0">{{ statusList[scope.row.orderStatus + 1].name }}</span>
          <span v-else>{{ statusList[scope.row.orderStatus + 1].name }}</span> -->
          <!-- <span >{{ statusList[scope.row.orderStatus + 1].name }}</span>
        </template> -->
      </el-table-column>
	  <el-table-column
	    prop="ext"
	    header-align="center"
	    align="center"
	    width="180"
	    label="收货信息">
		<template slot-scope="scope">
			<div style="text-align: left;">
				<div>
					{{ scope.row.ext ? '联系人：' + JSON.parse(scope.row.ext).contactsPerson : '' }}
				</div>
				<div>
					{{ scope.row.ext ? '手机号：' + JSON.parse(scope.row.ext).contactsPhone : '' }}
				</div>
				<div>
					{{ scope.row.ext ? '地址：' + JSON.parse(scope.row.ext).allAddress : '' }}
				</div>
			</div>
		</template>
	  </el-table-column>
	  <el-table-column
	    prop="ext"
	    header-align="center"
	    align="center"
	    width="180"
	    label="下单失败原因">
		<template slot-scope="scope">
			{{ scope.row.ext ? JSON.parse(scope.row.ext).errCodeDes : '' }}
		</template>
	  </el-table-column>
	  <el-table-column
	    prop="ext"
	    header-align="center"
	    align="center"
	    width="180"
	    label="下单订单号">
	  		<template slot-scope="scope">
	  			{{ scope.row.ext ? JSON.parse(scope.row.ext).suppliesOrderNo : '' }}
	  		</template>
	  </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="200"
        label="操作">
        <template slot-scope="scope">
          <!-- 只有自建奖品未领取才能核销 -->
          <el-button type="text" :disabled="scope.row.orderStatus != '未领取'" @click="setVerificationStatus(scope.row)" v-if="scope.row.orderStatus != '已核销' && scope.row.productType == 'myself'">核销</el-button>
          <el-button type="text" v-else-if="scope.row.orderStatus == '已核销'" disabled>已核销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <el-dialog title="核销奖品" :visible.sync="visible" width="40%" center>
      <p class="tips">请仔细核对奖品信息！</p>
      <ul class="prizeInfo" v-if="prizeData">
        <li>中奖玩家：{{ prizeData.nickname }}</li>
        <li>手机号码：{{ prizeData.phone }}</li>
        <li>奖项等级：{{ prizeData.prizeRank }}</li>
        <li>奖品：{{ prizeData.prizeName }}</li>
        <li>中奖券码：{{ prizeData.id }}</li>
        <li>中奖时间：{{ prizeData.createTime }}</li>
      </ul>
      <div style="margin-top: 50px; text-align: center">
        <el-button type="primary" @click="submit">确定核销</el-button>
        <el-button @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
	<el-dialog title="导出文件列表" :visible.sync="showExportListDialog" center>
		<div v-for="(file,index) of exportFileList" style="display: flex;justify-content: space-between;margin-bottom: 20px;">
			<div>{{ file.fileName }}</div>
			<el-button type="primary" size="mini" @click="down(file.fileName)">下载</el-button>
		</div>

	</el-dialog>
  </div>
</template>

<script>
import { getWinnerlist, verification } from '@/api/market'
import { queryExportFileList, exportFile, addExport } from '@/api/active'
import { getLocal } from '@/lib/local'
import myMixin from '@/lib/mixins'

export default {
  mixins: [myMixin],
  data () {
    return {
	  productType: '',
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      parameter: '',
      orderStatus: '',
      prizeData: '',
      visible: false,
      activityId: this.$route.query.activityId || '',
	  typeList: [
		  {
			  label: '全部',
			  value: ''
		  },
		  {
			  label: '实物',
			  value: 'materialObject'
		  }
	  ],
      statusList: [
        {
          value: '',
          name: '全部'
        },
        {
          value: 0,
          name: '未领取'
        },
        {
          value: 1,
          name: '已领取'
        },
        {
          value: 2,
          name: '已失效'
        },
        {
          value: 3,
          name: '已过期'
        },
        {
          value: 4,
          name: '已核销'
        },
      ],
      prizeRankList: ['一等奖', '二等奖', '三等奖', '四等奖', '五等奖', '六等奖',],
	  exportFileList: [], // 待导出文件列表
	  showExportListDialog: false, // 是否显示导出文件列表弹出层
    }
  },
  // created () {
  //   this.getDataList()
  // },
   methods: {
    //  获取数据
    async getDataList (parameter, orderStatus, queryTime) {
      // let {startTime,endTime} = queryTime
      const res = await getWinnerlist({pageNO: this.pageIndex, pageSize: this.pageSize, productType: this.productType, activityId: this.activityId, queryParam: parameter, orderStatus, ...queryTime })
      if(res.code == 200) {
        this.dataList = res.data.list
        this.totalPage = res.data.total
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList(this.parameter, this.orderStatus, this.form)
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList(this.parameter, this.orderStatus, this.form)
    },
    // 搜索
    searchInfo () {
      this.pageIndex = 1
      this.getDataList(this.parameter, this.orderStatus, this.form)
    },
    // 核销
    async setVerificationStatus (data) {
      this.visible = true
      this.prizeData = data
    },
    // 确定核销
    async submit () {
      const res = await verification({orderId: this.prizeData.id})
      if(res.code == 200) {
        this.$message.success('核销成功')
        this.visible = false
        this.pageIndex = 1
        this.getDataList(this.parameter, this.orderStatus, this.form)
      }
    },
    // 导出
    exportDataList () {
		if(!this.form.startTime || !this.form.endTime){
			this.$message.warning('请输入起始结束时间！')
			return
		}
      // debugger
	  addExport({
		  activityId: this.activityId,
		  queryParam: this.parameter,
		  orderStatus: this.orderStatus,
      productType: this.productType,
		  startTime: this.form.startTime,
		  endTime: this.form.endTime
	  }).then(res=>{
		  if(res.code == 200){
		   this.$message({
			   type: 'success',
			   message: '添加导出成功，请稍后点击『导出列表』查看！',
			   duration: 5000
		   })
		  } else {
			  this.$message.error(res.message)
		  }
	  })
      // window.location.href = `${this.$url}/cube-client/activity/player/export/win/order?activityId=${this.activityId}&queryParam=${this.parameter}&token=${getLocal()}&orderStatus=${this.orderStatus}&startTime=${this.form.startTime}&endTime=${this.form.endTime}`
      // window.location.href = `http://192.168.9.149:9003/cube-client/activity/player/export/win/order?activityId=${this.activityId}&queryParam=${this.parameter}&token=${getLocal()}&orderStatus=${this.orderStatus}`
    },
	// 查询导出文件列表
	showFileList(){
		queryExportFileList({
			activityId: this.$route.query.activityId
		}).then(res=>{
			if(res.code == 200){
				this.exportFileList = res.data
				if (res.data.length) {
					this.showExportListDialog = true
				} else {
					this.$message.info('暂无列表')
				}
			} else {
				this.$message.error(res.message)
			}
		})
	},
	down(name){
		exportFile({
			name
		})
	}
  }
}
</script>

<style lang="scss" scoped>
  .getPrize {
    .el-pagination {
      margin-top: 20px;
      text-align: right;
    }
    .search {
      margin: 20px 0;
    }
    .tips {
      color: red;
      font-size: 14px;
      // margin: 20px 0;
    }
    .prizeInfo {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        margin-top: 20px;
      }
    }
  }
</style>
