<template>
  <!-- 奖品数据 -->
  <div class="prizeData">
    <el-table
      :data="dataList"
      style="width: 100%;">
      <el-table-column
        prop="prizeName"
        header-align="center"
        align="center"
        width="150"
        label="奖品名称">
      </el-table-column>
      <el-table-column
        prop="prizeRank"
        header-align="center"
        align="center"
        label="奖品等级">
      </el-table-column>
      <el-table-column
        prop="productName"
        header-align="center"
        align="center"
        label="奖品类型">
      </el-table-column>
      <!-- <el-table-column
        prop="totalNum"
        header-align="center"
        align="center"
        label="奖池数量">
      </el-table-column> -->
      <el-table-column
        prop="leftNum"
        header-align="center"
        align="center"
        width="120"
        label="奖池剩余数量">
      </el-table-column>
      <el-table-column
        prop="winNum"
        header-align="center"
        align="center"
        label="已中奖">
      </el-table-column>
      <el-table-column
        prop="receivedNum"
        header-align="center"
        align="center"
        label="已领取">
      </el-table-column>
      <el-table-column
        prop="verificationNum"
        header-align="center"
        align="center"
        label="已核销">
      </el-table-column>
      <el-table-column
        prop="notReceivedNum"
        header-align="center"
        align="center"
        label="未领取">
      </el-table-column>
      <el-table-column
        prop="expiredNum"
        header-align="center"
        align="center"
        label="已过期">
      </el-table-column>
      <el-table-column
        prop="invalidNum"
        header-align="center"
        align="center"
        label="已失效">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="200"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editActive(scope.row.prizeRank)">修改</el-button>
          <el-button type="text" size="small" @click="returnResource(scope.row.prizeId)" v-if="status == 3 && scope.row.notReceivedNum">退回资产</el-button>
          <el-button type="text" size="small" disabled v-else>退回资产</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination> -->
  </div>
</template>

<script>
import { getPrizeData, returnGoods, isCalData } from '@/api/market'

export default {
  data () {
    return {
      dataList: [],
      // pageIndex: 1,
      // pageSize: 10,
      // totalPage: 0,
      activityId: this.$route.query.activityId || '',
      status: this.$route.params.status || '',
      // isRun: this.$route.query.isRun || '',
    }
  },
  // created () {
  //   this.getDataList()
  // },
  methods: {
    async getDataList () {
      const res = await getPrizeData({activityId: this.activityId})
      if(res.code == 200) {
        this.dataList = res.data
      }
    },
    // 修改
    async editActive (prizeRank) {
      let level = null
      switch(prizeRank.split('')[0]) {
        case '一':
          level = 0
          break;
        case '二':
          level = 1
          break;
        case '三':
          level = 2
          break;
        case '四':
          level = 3
          break;
        case '五':
          level = 4
          break;
        case '六':
          level = 5
      }
      const res = await isCalData({id: this.activityId})
      if(res.code == 200) {
        let { href } = this.$router.resolve({ path: '/MarketingActivityEdit', query: {activityId: this.activityId, level: level} })
        window.open(href, '_blank')
      }else if(res.code == 8013) {
        this.$message('物资结算中,请稍后再编辑...')
      }
    },
    // 退回资产
    async returnResource (prizeId) {
      const res = await returnGoods({activityId: this.activityId, prizeId})
      if(res.code == 200) {
        this.$message.success('退回资产成功')
        this.getDataList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .prizeData {
    .el-pagination {
      margin-top: 20px;
      text-align: right;
    }
  }
</style>