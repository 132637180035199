<template>
  <!-- 营销活动数据 -->
  <div class="activityData">
    <div class="activity-title">
      <h3 class="title" v-if="detailData.name">营销活动数据 <span>{{ ` -- ${detailData.name}(${detailData.num})` }}</span></h3>
    </div>
    <div class="show-data">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="传播数据" name="first">
          <data-show ref="dataShow" @getActiveTitle="getActiveTitle"></data-show>
        </el-tab-pane>
        <el-tab-pane label="奖品数据" name="second">
          <prize-data ref="prizeData"></prize-data>
        </el-tab-pane>
        <el-tab-pane label="玩家列表" name="third">
          <player-list ref="playerList"></player-list>
        </el-tab-pane>
        <el-tab-pane label="获奖名单" name="four">
          <get-prize ref="getPrize"></get-prize>
        </el-tab-pane>
        <el-tab-pane label="黑名单" name="five">
          <black-list ref="blackList"></black-list>
        </el-tab-pane>
        <el-tab-pane label="开奖" name="six" v-if="$route.query.templeType == 19">
          <OpenDraw ref="openDraw" />
        </el-tab-pane>
        <el-tab-pane label="图片审核" name="seven" v-if="$route.query.templeType == 26">
          <ApprovalImage ref="approvalImage" />
        </el-tab-pane>
        <el-tab-pane label="奖品补发" name="eight">
          <PrizeReissue key="PrizeReissue" ref="PrizeReissue"></PrizeReissue>
        </el-tab-pane>
        <el-tab-pane label="补发成功" name="nine">
          <PrizeReissue key="reissueSuccess" ref="reissueSuccess" :success="true"></PrizeReissue>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DataShow from './DataShow.vue'
import PrizeData from './PrizeData.vue'
import PlayerList from './PlayerList.vue'
import GetPrize from './GetPrize.vue'
import BlackList from './BlackList.vue'
import OpenDraw from './openDraw.vue'
import ApprovalImage from './approvalImage.vue'
import PrizeReissue from './prizeReissue'
export default {
  components: {
    DataShow,
    PrizeData,
    PlayerList,
    GetPrize,
    BlackList,
    OpenDraw,
    ApprovalImage,
    PrizeReissue
  },
  data () {
    return {
      activeName: 'first',
      detailData: {}
    }
  },
  mounted () {
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab
      this.handleClick({name: this.$route.query.tab})
    }
  },
  methods: {
    handleClick (tab) {
      if (tab.name === 'first') {
        this.$refs.dataShow.getChart()
        this.$refs.dataShow.getActiveDetail()
      } else if (tab.name === 'second') {
        this.$refs.prizeData.getDataList()
      } else if (tab.name === 'third') {
        this.$refs.playerList.getDataList()
        this.$refs.playerList.queryParam = ''
      } else if (tab.name === 'four') {
        this.$refs.getPrize.getDataList()
        this.$refs.getPrize.queryParam = ''
      } else if (tab.name === 'five') {
        this.$refs.blackList.getDataList()
        this.$refs.blackList.queryParam = ''
      } else if (tab.name === 'seven') {
        this.$refs.approvalImage.queryList()
      }
    },
    getActiveTitle (data) {
      this.detailData = data
    }
  }
}
</script>

<style lang="scss" scoped>
  .activityData {
    .activity-title {
      height: 70px;
      line-height: 70px;
      padding-left: 360px;
      border-bottom: 2px solid #ccc;
    }
    .show-data {
      width: 1200px;
      margin: 30px auto 0;
      /deep/ .el-tabs__item {
        padding: 0 40px;
      }
    }
    .title {
      font-weight: 400;
      span {
        font-size: 16px;
        color: #666;
      }
    }
  }
</style>
